import './index.scss';
import * as serviceWorker from './serviceWorker';
import io from 'socket.io-client';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
var myPeerConnection;
var transceiver;

function log (text) {
  var time = new Date();

  console.log('[' + time.toLocaleTimeString() + '] ' + text);
}

// Output an error message to console.

function log_error (text) {
  var time = new Date();

  console.trace('[' + time.toLocaleTimeString() + '] ' + text);
}

function reportError (errMessage) {
  log_error(`Error ${errMessage.name}: ${errMessage.message}`);
}

var webcamStream;

function createPeerConnection (broadcast) {

  console.log('createPeerConnection');
  myPeerConnection = new RTCPeerConnection({
    iceServers: [     // Information about ICE servers - Use your own!
      // {
      //   urls: 'stun:localhost:3478'
      // },
      {
        urls: 'stun:v.just-journal.app:3478'
      },
      {
        urls: 'stun:stun.l.google.com:19302'
      }
    ]
  });

  myPeerConnection.onicecandidate = event => {
    if (event.candidate) {
      broadcast('new-ice-candidate', {candidate: event.candidate});
    }
  };
  myPeerConnection.ontrack = handleTrackEvent;
  myPeerConnection.onnegotiationneeded = handleNegotiationNeededEvent;
  myPeerConnection.onremovetrack = handleRemoveTrackEvent;
  myPeerConnection.oniceconnectionstatechange = handleICEConnectionStateChangeEvent;
  myPeerConnection.onicegatheringstatechange = (ev => {
    console.log('onicegatheringstatechange', ev.target.iceGatheringState);
  });
  myPeerConnection.onsignalingstatechange = handleSignalingStateChangeEvent;


  async function handleNegotiationNeededEvent () {
    log('*** Negotiation needed');

    try {
      log('---> Creating offer');
      const offer = await myPeerConnection.createOffer({offerToReceiveAudio: true, offerToReceiveVideo: true});

      // If the connection hasn't yet achieved the "stable" state,
      // return to the caller. Another negotiationneeded event
      // will be fired when the state stabilizes.

      if (myPeerConnection.signalingState != 'stable') {
        log('     -- The connection isn\'t stable yet; postponing...');
        return;
      }

      // Establish the offer as the local peer's current
      // description.

      log('---> Setting local description to the offer');
      await myPeerConnection.setLocalDescription(offer);

      // Send the offer to the remote peer.

      log('---> Sending the offer to the remote peer');
      if (!myPeerConnection.localDescription.sdp) {
        debugger
      }
      broadcast('video-offer', {sdp: myPeerConnection.localDescription});

    } catch (err) {
      log('*** The following error occurred while handling the negotiationneeded event:');
      reportError(err);
    }
    ;
  }

  return myPeerConnection;
}

var targetUsername = 'user1';


function closeVideoCall () {
  var localVideo = document.getElementById('local_video');

  log('Closing the call');

  // Close the RTCPeerConnection

  if (myPeerConnection) {
    log('--> Closing the peer connection');

    // Disconnect all our event listeners; we don't want stray events
    // to interfere with the hangup while it's ongoing.

    myPeerConnection.ontrack = null;
    myPeerConnection.onnicecandidate = null;
    myPeerConnection.oniceconnectionstatechange = null;
    myPeerConnection.onsignalingstatechange = null;
    myPeerConnection.onicegatheringstatechange = null;
    myPeerConnection.onnotificationneeded = null;

    // Stop all transceivers on the connection

    myPeerConnection.getTransceivers().forEach(transceiver => {
      transceiver.stop();
    });

    // Stop the webcam preview as well by pausing the <video>
    // element, then stopping each of the getUserMedia() tracks
    // on it.

    if (localVideo.srcObject) {
      localVideo.pause();
      localVideo.srcObject.getTracks().forEach(track => {
        track.stop();
      });
    }

    // Close the peer connection

    myPeerConnection.close();
    myPeerConnection = null;
    webcamStream = null;
  }

  // Disable the hangup button

  document.getElementById('hangup-button').disabled = true;
  targetUsername = null;
}

function handleTrackEvent (event) {
  console.log(event);
  document.getElementById('received_video').srcObject = event.streams[0];
  // document.getElementById('hangup-button').disabled = false;
}


function handleICEGatheringStateChangeEvent (event) {

  // Our sample just logs information to console here,
  // but you can do whatever you need.
}

function handleRemoveTrackEvent (event) {
  console.log(event);
  var stream = document.getElementById('received_video').srcObject;
  var trackList = stream.getTracks();

  if (trackList.length == 0) {
    closeVideoCall();
  }
}

function handleICEConnectionStateChangeEvent (event) {
  console.log(event);
  switch (myPeerConnection.iceConnectionState) {
  case 'closed':
  case 'failed':
  case 'disconnected':
    closeVideoCall();
    break;
  }
}

function handleSignalingStateChangeEvent (event) {
  console.log(event);
  switch (myPeerConnection.signalingState) {
  case 'closed':
    closeVideoCall();
    break;
  }
};


function getUserMedia (constraints) {
  if (navigator.mediaDevices) {
    return navigator.mediaDevices.getUserMedia(constraints);

  }
  return (new Promise((resolve, reject) => navigator.getUserMedia(constraints, resolve, reject)));
}

// console.log(navigator.mediaDevices.getSupportedConstraints());
setTimeout(() => {
  navigator.mediaDevices.enumerateDevices()
    .then(list => {
      // debugger
      // console.log(list);
      return list.map(ddev => ddev.label).join('<br>');
    })
    .then(m => document.getElementById('message').innerHTML = m).then(

  );
}, 100);

// getUserMedia({video: true, audio: true}).then(stream => {
//   video = document.getElementById('basic-stream');
//   video.srcObject = (stream);
// }).catch(e => {
//   document.getElementById('message').innerText = e.message;
// });

var connect_error_count = 0;

function socketConnection () {
  return new Promise(resolve => {

    var socket = io.connect(
      '/',
      {
        'reconnectionDelay': 10 // defaults to 500
      }
    );


    socket.on('connect_error', function () {
      console.log('Connection Failed');
      //Если более 5 попыток переподключения, то отключаем подключение
      connect_error_count++;
      if (connect_error_count >= 5) {
        socket.disconnect();
        console.log('stop reconection');
      }
    });


    socket.on('reconnect', function () {
      console.log('reconnect');
      connect_error_count = 0;
    });


    socket.on('news', function (data) {
      console.log(data);
    });
    socket.on('id', function () {

    });

    socket.on('connect', () => resolve(socket));


  });
}

function randomString (len) {
  var random = new Uint8Array(len * 2);
  window.crypto.getRandomValues(random);
  return [...random].map(x => x.toString(32)).join('').substr(0, len);
}


function getRoomId () {
  return document.location.pathname.substr(1);
}

function navigatetoNewRoom () {
  document.location = randomString(16);
}


async function handleNewICECandidateMsg (peerConnection, candidateStr) {
  // var candidate = new RTCIceCandidate(candidateStr.candidate);

  console.log('*** Adding received ICE candidate: ' + JSON.stringify(candidateStr));
  try {
    await peerConnection.addIceCandidate(candidateStr);
  } catch (err) {
    console.error(err);
  }
}

function handleGetUserMediaError (err) {
  console.error(err);

}

function attachMyStream (webcamStream, myPeerConnection) {
  try {
    webcamStream.getTracks().forEach(
      transceiver = track => myPeerConnection.addTransceiver(track, {streams: [webcamStream]})
    );
  } catch (err) {
    handleGetUserMediaError(err);
  }
}

function roomReady (socket) {
  return new Promise(resolve => socket.once('room-ready', resolve));
}


async function handleVideoOfferMsg (msg, broadcast, attachMyStream) {
  targetUsername = msg.name;

  // If we're not already connected, create an RTCPeerConnection
  // to be linked to the caller.

  log('Received video chat offer from ' + targetUsername);
  if (!myPeerConnection) {
    createPeerConnection(broadcast);
  }

  // We need to set the remote description to the received SDP offer
  // so that our local WebRTC layer knows how to talk to the caller.

  var desc = new RTCSessionDescription(msg.sdp);

  // If the connection isn't stable yet, wait for it...

  if (myPeerConnection.signalingState != 'stable') {
    log('  - But the signaling state isn\'t stable, so triggering rollback');

    // Set the local and remove descriptions for rollback; don't proceed
    // until both return.
    await Promise.all([
      myPeerConnection.setLocalDescription({type: 'rollback'}),
      myPeerConnection.setRemoteDescription(desc)
    ]);
    return;
  } else {
    log('  - Setting remote description');
    await myPeerConnection.setRemoteDescription(desc);
  }

  // Get the webcam stream if we don't already have it


  if (attachMyStream) {
    attachMyStream(webcamStream, myPeerConnection);
  }

  log('---> Creating and sending answer to caller');

  await myPeerConnection.setLocalDescription(await myPeerConnection.createAnswer());

  broadcast('video-answer', {
    sdp: myPeerConnection.localDescription
  });
}

// Responds to the "video-answer" message sent to the caller
// once the callee has decided to accept our request to talk.

async function handleVideoAnswerMsg (msg) {
  log('*** Call recipient has accepted our call');

  // Configure the remote description, which is the SDP payload
  // in our "video-answer" message.

  var desc = new RTCSessionDescription(msg.sdp);
  await myPeerConnection.setRemoteDescription(desc).catch(reportError);
  // document.getElementById('video_blocks').style.top = '0';
}

function copyUrl () {
  var dummy = document.createElement('input'),
    text = window.location.href;

  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
}

(async function () {
  const roomId = getRoomId();
  if (!roomId) {
    return navigatetoNewRoom();
  }
  copyUrl();
  // document.getElementById('roomlink').href = document.location.pathname;
  // document.getElementById('roomlink').innerText = document.location.href;
  const socket = await socketConnection();
  // webcamStream = await getUserMedia({video: true});


  let videoStream;
  let capturedStream;
  const captureVideo = async () => {
    capturedStream = videoStream = await getUserMedia({video: true});
  };

  const releaseVideo = async () => {
    capturedStream.getVideoTracks().forEach(v => v.stop());
  };
  var gainNode;

  function muteSound () {
    audioStream.getAudioTracks().forEach(v => v.enabled = false);
    isAudioON = false;
    document.querySelector('.audio-switch-button .off').style.display = 'block';
    document.querySelector('.audio-switch-button .on').style.display = 'none';
  }

  function UnMuteSound () {
    audioStream.getAudioTracks().forEach(v => v.enabled = true);
    isAudioON = true;
    document.querySelector('.audio-switch-button .on').style.display = 'block';
    document.querySelector('.audio-switch-button .off').style.display = 'none';
  }

  await captureVideo();
  let audioStream = await getUserMedia({audio: true}).then(stream => {
    var audioCtx = new (window.AudioContext || window.webkitAudioContext)(); // define audio context

    var source = audioCtx.createMediaStreamSource(stream);
    gainNode = audioCtx.createGain();
    var dest = audioCtx.createMediaStreamDestination();
    source.connect(gainNode);
    gainNode.connect(dest);
    return dest.stream;
  });
  UnMuteSound();

  let tempOn = false;
  window.addEventListener('keydown', e => {
    if (isAudioON) {
      return;
    }
    if (e.key === ' ') {
      // add listener temp on
      tempOn = true;
      UnMuteSound();
      let listener = () => {
        window.removeEventListener('keyup', listener);
        tempOn = false;
        muteSound();
      };
      window.addEventListener('keyup', listener);
    }
  });

  window.webcamStream = webcamStream = new MediaStream([...videoStream.getTracks(), ...audioStream.getTracks()]);
  let isVideoON = true;
  let isAudioON = true;
  document.querySelector('.video-switch-button .on').style.display = 'block';
  document.querySelector('.video-switch-button .off').style.display = 'none';
  document.querySelector('.audio-switch-button .on').style.display = 'block';
  document.querySelector('.audio-switch-button .off').style.display = 'none';
  document.getElementById('local_video').srcObject = webcamStream;
  document.getElementById('local_video').muted = true;
  socket.emit('join-room', {roomId});

  // document.querySelector('.video-switch-button').addEventListener('click', async () => {
  //   if (isVideoON) {
  //     await releaseVideo();
  //     // videoStream.getVideoTracks().forEach(v => v.enabled = false);
  //     isVideoON = false;
  //     document.querySelector('.video-switch-button .off').style.display = 'block';
  //     document.querySelector('.video-switch-button .on').style.display = 'none';
  //     // document.getElementById('local_video').srcObject = null;
  //   } else {
  //     await captureVideo();
  //     // videoStream.getVideoTracks().forEach(v => v.enabled = true);
  //     // // videoStream = await getUserMedia({video: true});
  //     // // attachMyStream(videoStream, peerConnection);
  //     // document.getElementById('local_video').srcObject = videoStream;
  //     // document.getElementById('local_video').muted = true;
  //     isVideoON = true;
  //     document.querySelector('.video-switch-button .on').style.display = 'block';
  //     document.querySelector('.video-switch-button .off').style.display = 'none';
  //   }
  // });
  document.querySelector('.audio-switch-button').addEventListener('click', async () => {
    if (isAudioON) {
      muteSound();
    } else {
      UnMuteSound();
    }
  });

  function broadcast (event, data) {
    socket.emit('to-room', {roomId, event, data});
  }


  let peerConnection;

  // await roomReady(socket);

  let isVideoAttached = false;
  socket.on('start-call', () => {
    peerConnection = createPeerConnection(broadcast);
    attachMyStream(webcamStream, peerConnection);
    isVideoAttached = true;
  });
  socket.on('new-ice-candidate', ({candidate}) => {
    handleNewICECandidateMsg(peerConnection, candidate);
  });

  socket.on('video-offer', (data) => {
    if (!peerConnection) {
      peerConnection = createPeerConnection(broadcast);
    }
    console.log('video-offer', data);
    handleVideoOfferMsg(data, broadcast, isVideoAttached ? null : attachMyStream);
  });
  socket.on('video-answer', (data) => {
    handleVideoAnswerMsg(data);
  });


  // Add the tracks from the stream to the RTCPeerConnection


  // wait candidates


  // socket.once('ready', )
  // host establish signal channel
  // TODO: check is there any host yet.
  // if there host - you client go to "client receive offer" step
  // host create room
  // host create peerConnection
  // host create offer
  // host attach media stream
  // host broadcast offer
  // if client connected - he recieve offer immedietally otherwise he should request it on connecting to room
  // if there's no host yet - client become host.
  // client receive offer
  // client create peerConnection
  // client create offer
  // client attach media stream
  // client send answer

  // host reveive answer
  // ice candidates exchange

  // working

  // close

  // create
})();

function onresize () {
  if (window.innerHeight / window.innerWidth > 1.5) {
    // tall slim
    document.body.classList.remove('old43');
    document.body.classList.remove('wide_short');
    document.body.classList.add('tall_slim');
  } else if (window.innerWidth / window.innerHeight > 1.5) {
    // wide short
    document.body.classList.remove('old43');
    document.body.classList.remove('tall_slim');
    document.body.classList.add('wide_short');
  } else {
    // near to 4/3
    document.body.classList.remove('tall_slim');
    document.body.classList.remove('wide_short');
    document.body.classList.add('old43');

  }

}

window.onresize = onresize;
onresize();
